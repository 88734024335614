import axios from 'axios'
import { createBrowserHistory } from 'history'
import { BrowserRouter } from 'react-router-dom'
import { getLocalStorage, setLocalStorage, fixTimezone, OffsetDirection } from './utils'

// Setup our own history instance so we can use it outside of react.
// E.g. in our axios setup below.
export const routerHistory = createBrowserHistory()

export function setupAxios() {
  // Configure axios to grab the CSRF token from the cookie and set it
  // in our headers.
  axios.defaults.xsrfCookieName = 'CSRF-TOKEN'
  axios.defaults.xsrfHeaderName = 'X-CSRF-Token'
  axios.defaults.withCredentials = true

  axios.interceptors.response.use(
    function (response) {
      fixTimezone(response, OffsetDirection.FORWARD)
      return response
    },
    function (error) {
      // Check for any 401 UnAuthorized requests and make sure we are logged out
      // and redirect to login.
      if (error.response?.status === 401) {
        setLocalStorage('loggedIn', false)
        setTimeout(() => routerHistory.push('/login'))
      }
      return Promise.reject(error)
    }
  )
  axios.interceptors.request.use(function (config) {
    if (
      config.method !== 'get' &&
      config.data &&
      typeof config.data === 'object'
    ) {
      fixTimezone(config.data, OffsetDirection.BACKWARD)
    }
    return config
  })

  // Redirect to login unless we are viewing a noAuthPage OR we are logged in.
  const notLoggedInPages = [
    '/login',
    '/sign-up',
    '/forgot-password',
    '/reset-password',
    '/accept-invite',
    '/pdf',
    '/view',
  ]
  if (
    !(
      notLoggedInPages.some((page) =>
        window.location.pathname.startsWith(page)
      ) ||
      (getLocalStorage('loggedIn') && getLocalStorage('user'))
    )
  ) {
    routerHistory.push('/login')
  }
}

// Setup a special Router that has our history instance.
export class OurBrowserRouter extends BrowserRouter {
  history = routerHistory
}
