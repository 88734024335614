import axios from 'axios'
import { useState } from 'react'
import { getApiUrl, useEffectOnce } from '../utils'

type CrudInstance = {
 fullId: number | string
}

type CrudClass<T> = {
 build(props: any): T
 apiPath: string
}

/*
A react hook that manages CRUD operations for resources.
 1. Auto fetches list and exposes loading status.
 2. Provides add, update, destroy methods which will keep list sync'd.

Usage:
 1. const resourceCrud = useCrud(ResourceClass) -- e.g. useCrud(Trip)
 2. ResourceClass must have a "build" factory that takes an object and returns an instance.
 3. ResourceClass must define an "apiPath".
 4. Instances of ResourceClass must define a "fullId".
*/
export function useCrud<T extends CrudInstance>(
 Type: CrudClass<T>,
 loadList: boolean = true
) {
 const [data, setData] = useState<T[]>([])
 const [loading, setLoading] = useState(true)

 useEffectOnce(() => {
   if (loadList) {
     refetchList()
   }
 })

 const refetchList = (data?: any) => {
   setLoading(true)
   return axios.get(getApiUrl(Type.apiPath), { params: data }).then((res) => {
     setData(res.data.map((d: any) => Type.build(d)))
     setLoading(false)
   })
 }

 const get = (id?: string, extra?: string) => {

  return axios.get(getApiUrl(Type.apiPath, id, extra)).then((res) => {
     return Type.build(res.data)
   })
 }

 const add = (toAdd: any) => {
   return axios.post(getApiUrl(Type.apiPath), toAdd).then((res) => {
     const obj = Type.build({
       ...res.data,
       type: res.data.type ,
     })
     setData([...data, obj])
     return obj
   })
 }

 const update = (newData: any, extra?: string) => {

   const obj = {
     ...newData,
     //*** we are changing notes to note because when we send a content, we must pass a singular type */
     type: newData.type === 'notes' ? 'note' : newData.type,

   }
   const fullId = Type.build(obj).fullId
   return axios
     .patch(getApiUrl(Type.apiPath, fullId, extra), obj)
     .then(({ data: updatedRecord }) => {
       setData(
         data.map((record) => {
           if (record.fullId === fullId) {
             return Type.build(updatedRecord)
           }
           return record
         })
       )
       return updatedRecord
     })
     .catch((res) => {
       // Pass the body of the response back so that consumers can access it
       // in their try/catch. See StripeBillingForm for an example.
       throw res.response.data
     })
 }

 const duplicate = (toDuplicate: any) => {
   const newData = { title: toDuplicate.title }
   return axios
     .post(getApiUrl(Type.apiPath, toDuplicate.id, 'duplicate'), newData)
     .then((res) => {
       const obj = Type.build(res.data)
       setData([...data, obj])
       return obj
     })
 }

 const purchase = (toPurchase: any) => {
   return axios.post(getApiUrl(Type.apiPath, toPurchase.id, 'purchase'))
 }

 const destroy = (toDelete: any) => {
   const fullId = Type.build(toDelete).fullId

   return axios.delete(getApiUrl(Type.apiPath, fullId)).then(() => {
     let dataDelete = [...data]
     // Find our removed row by id and type.
     const index = dataDelete.findIndex((d) => d.fullId === fullId)
     dataDelete.splice(index, 1)
     setData(dataDelete)
   })
 }

 return {
   refetchList,
   list: data,
   loading: loading,
   get,
   add,
   update,
   duplicate,
   destroy,
   purchase,
 }
}
