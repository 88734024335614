import axios from 'axios'
import { addDays } from 'date-fns'
import { computed, observable, decorate } from 'mobx';
import { getApiUrl } from '../helpers/utils'
import { ContentItem } from './content'
import { ContentType } from 'models/ContentType'
import { ItemType, ItineraryItem } from './items/'
import { BuildItineraryItemInterface } from './items/base'
import { Trip } from './trip'

export type DayInterface = {
  id: number
  title: string
  items: BuildItineraryItemInterface[]
}

type Position = {
  start: { day: number; position: number }
  end?: { day: number; position: number }
}

export type PositionChange = {
  before?: Position
  after: Position
}

type ItemResponse = {
  tripDays: DayInterface[]
  positionChange: PositionChange
  item: BuildItineraryItemInterface
}

export class Day {
  trip: Trip
  id: number
  title: string;
  readonly items = observable<ItineraryItem>([])

  static apiPath = 'days'

  constructor(day: DayInterface, trip: Trip) {
    this.trip = trip
    this.id = day.id
    this.title = day.title
    this.replaceItems(day.items)
  }

  replaceItems(items: BuildItineraryItemInterface[]) {
    this.items.replace(items.map((item: any) => ItineraryItem.build(item)))
  }

  get date() {
    return addDays(this.trip.startAt, this.position)
  }

  get position() {
    return this.trip.days.indexOf(this)
  }

  get fullTitle() {
    return `Day ${this.position + 1} — ${this.title}`
  }

  updateTitle(title: string) {
    // Perform optimistic update.
    this.title = title

    // Perform real update.
    return axios.patch(getApiUrl(Day.apiPath, this.id), { title }).then()
  }

  updateItem(item: ItineraryItem, data: any) {

    data.reconfirm = item.needsReconfirm(this.date)

    return axios
      .patch(getApiUrl(ItineraryItem.apiPath, item.fullId), data)
      .then((res: { data: ItemResponse }) => {
        this.trip.replaceDays(res.data.tripDays)
        return res.data.positionChange
      })
  }

  removeItem(item: ItineraryItem) {
    // Perform optimistic update.
    const removed = this.items.remove(item)
    if (!removed) {
      console.error(
        'Attempted to remove non-existent itinerary item from day: ',
        item
      )
      return
    }

    // Perform real update.
    return axios
      .delete(getApiUrl(ItineraryItem.apiPath, item.fullId), {
        data: { type: item.type },
      })
      .then((res: { data: ItemResponse }) => {
        // It's important to replace days as the removed item may belong to
        // multiple days.
        this.trip.replaceDays(res.data.tripDays)
      })
  }

  reorderItems(sourceIndex: number, destinationIndex: number) {
    // Perform optimistic update.
    let newItems = this.items.slice()
    newItems.splice(sourceIndex, 1)
    newItems.splice(destinationIndex, 0, this.items[sourceIndex])
    this.items.replace(newItems)

    // Perform real update.
    return axios
      .patch(getApiUrl(Day.apiPath, this.id, 'reorder'), {
        sourceIndex,
        destinationIndex,
      })
      .then()
  }

  addContentItem(content: ContentItem, destinationIndex: number) {
    if (content.type !== ContentType.FULLDAY) {
      // Perform optimistic update.
      const tempItem = ItineraryItem.build(content as any)
      tempItem.id += new Date().getTime() // Fixes issue when adding multiple of the same.

      this.items.splice(destinationIndex, 0, tempItem)
    }

    // Perform real update.
    return this.postItem(content.type, {}, content.id, destinationIndex)
      .then((res: { data: ItemResponse }) => {
        this.trip.replaceDays(res.data.tripDays)
      })
  }

  postItem(
    type: ItemType | ContentType,
    item: any,
    content_id: number | undefined,
    position: number
  ) {
    return axios
      .post(getApiUrl(ItineraryItem.apiPath, type), {
        day_id: this.id,
        content_id,
        position,
        ...item,
      })
  }

  addItem(
    type: ItemType | ContentType,
    item: any,
    content_id: number | undefined,
    position: number
  ) {
    return this.postItem(type, item, content_id, position)
      .then((res: { data: ItemResponse }) => {
        this.trip.replaceDays(res.data.tripDays)
        return {
          positionChange: res.data.positionChange,
          item: ItineraryItem.build(res.data.item),
        }
      })
  }

  get needReconfirmCount() {
    let count = 0
    this.items.forEach((item) => {
      if (item.needsReconfirm(this.date)) {
        count += 1
      }
    })
    return count
  }
}

decorate(Day, {
  title: observable,
  date: computed,
  position: computed,
  fullTitle: computed
});
