import { Agency } from 'models/Agency'

export type UserInterface = {
  id: string
  email: string
  phoneNumber: string
  name: string
  personalAgency: Agency
  agency: Agency | null
  agencyPays: boolean
  agencyOwner: boolean
  status: string
  pdfFontSize: string
  pdfPageBreak: boolean
  storeTermsAcceptedAt: string
}

export interface User extends UserInterface {}
export class User {
  constructor(user: UserInterface) {
    Object.assign(this, user)
  }

  static build(user: UserInterface) {
    const u = new User(user)
    if (user.personalAgency)
      u.personalAgency = Agency.build(user.personalAgency)
    if (user.agency)
      u.agency = Agency.build(user.agency)
    return u
  }

  get fullId() {
    return this.id
  }

  get trialing() {
    return this.status === "trialing"
  }

  static apiPath = 'users'
}
